const Info = (): JSX.Element => {
  return (
    <div className="p-2 h-[50rem]">
      <div className="flex">
        <div>사장님 알림</div>
      </div>
    </div>
  );
};
export default Info;
